"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Variants = void 0;
const Component_1 = require("./Component");
const api_1 = require("./api");
const Routes_1 = require("./Routes");
class Variants extends Component_1.Component {
    suraSelect;
    verseSelect;
    constructor(id) {
        super(id, Variants.name);
        this.suraSelect = $("input[name='sure']").get();
        this.verseSelect = $("input[name='vers']").get();
        $(this.verseSelect).on('change', () => this.changeVariantsForm());
        $(this.suraSelect).on('change', () => this.changeVariantsForm());
        console.log("Have the sura menu", this.suraSelect);
        console.log("Have the verse menu", this.verseSelect);
    }
    changeVariantsForm() {
        console.log('Getting variants!!');
        const sura = $(this.suraSelect).val();
        const verse = $(this.verseSelect).val();
        if (sura && verse) {
            (0, api_1.fetchMarkup)(Routes_1.Routes.variantsForm(+sura, +verse), (markup) => {
                console.log(markup);
                $('#varianten')
                    .children()
                    .remove();
                $('#varianten').append(markup);
            });
        }
    }
    ;
}
exports.Variants = Variants;
