"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Routes = void 0;
class Routes {
    static maxWords = `/quran/maxWords`;
    static newMapping(verseOnly = true) {
        if (verseOnly) {
            return '/intertext/new-mapping';
        }
        return `/manuscript-page/new-mapping`;
    }
    static quranWords(sura, verse) {
        return `/quran/sura/${sura}/verse/${verse}`;
    }
    static variantsForm(sura, verse) {
        return `/reading-variants/sura/${sura}/verse/${verse}`;
    }
}
exports.Routes = Routes;
